

$(() => {

  // delete confirm
  $(document).on('click', 'a', function() {
    const message = $(this).data('confirm');
    return !message || window.confirm(message);
  });


  // bootstrap tooltips
  $(document).on('mouseenter', '[data-tooltip]', function() {
    const el = $(this).get(0);
    if (!el || $(this).data('bs-tooltip')) {
      return;
    }
    const tooltip = new bootstrap.Tooltip(el, { placement: 'bottom', title: $(this).data('tooltip') });
    tooltip.show();
    $(this).data('bs-tooltip', tooltip);
  });
});
