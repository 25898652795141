
$(() => {

  $('.radial-chart').each(function() {
    const value = $(this).data('value');
    const color = $(this).data('color');
    const label = $(this).data('label');

    const options = {
      series: [ Number(value) ],
      chart: {
        height: 105,
        width: 105,
        type: 'radialBar',
      },
      grid: {
        padding: {
          top: -15
        }
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '40%',
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: 4,
              fontSize: '15px'
            }
          }
        },
      },
    };
    options.colors = color ? [ color ] : undefined;
    options.labels = label ? [ label ] : [ '' ];

    const chart = new window.ApexCharts(this, options);
    chart.render();
  });
});
