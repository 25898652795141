// css
require('../scss/main.scss');

// js

require('./utils/cleave');
require('./utils/toasts');
require('./utils/daterange');
require('./utils/common');
require('./utils/audio');
require('./utils/auto-toggle');

require('./catalog/trainings');
require('./catalog/products');

require('./client/client');

require('./crm/clients');

require('./planner/sessions');
require('./planner/clients-contacts');
require('./planner/instructors-calendar');
require('./planner/registrations');
require('./planner/revenue-refresh');
require('./planner/planning');
require('./client/medical_certificate_end_date');
require('./planner/trainees');
require('./planner/form-categories');
require('./planner/form-indices');
require('./planner/form-tst-modules');
require('./planner/assign');
require('./planner/discounts');
require('./planner/extra-products');
require('./planner/pennylane-debt');

require('./tp/errors');
require('./tp/repeater-criterias');

require('./tt/repeater-questions');


import { filterSelect } from './utils/filter';


$(() => {
  // datatables
  $('table.datatable').dataTable({
    paging: true,
    pageLength: 50,
    lengthChange: false,
    order: [],
    stateSave: true,
    deferRender: false,
    responsive: true,
    language: {
      info: 'Affichage de _START_ à _END_ sur _TOTAL_ enregistrements',
      infoEmpty: '0 enregistrement',
      search: 'Recherche rapide',
      emptyTable: 'Aucune donnée à afficher',
      zeroRecords: 'Aucune donnée à afficher',
      infoFiltered: '- filtré sur _MAX_ enregistrement(s)',
      paginate: {
        first:      'Premier',
        last:       'Dernier',
        next:       'Suiv.',
        previous:   'Préc.'
      },
    },
    initComplete: function() {
      $(this).css('opacity', 1);
    }
  });

  // alert
  $(document).on('click', 'a', function() {
    const message = $(this).data('alert');
    return !message || window.alert(message);
  });

  // toggle visibility on click
  $(document).on('click', '.toggle-visibility', function() {
    const target = $(this).data('target');
    $(target).toggle();
    return false;
  });

  // auto active tabs
  const { hash } = window.location;
  if (hash && $(`ul.hash-nav a[href="${hash}"]`).length) {
    $(`ul.hash-nav a[href="${hash}"]`).tab('show');
  } else {
    $('ul.hash-nav li:first-child a.nav-link').tab('show');
  }
  $(document).on('click', 'ul.hash-nav a', function() {
    $(this).tab('show');
  });

  // prevent scroll to tab id
  $('.hash-nav a.no-scroll-link').on('click', function (e) {
    e.preventDefault();
    $(this).tab('show');
    history.replaceState({}, '', $(this).attr('href'));
  });

  //
  filterSelect('#training_id', '#training_type_id', 'training_id');
  filterSelect('#training_id', '#program_id', 'training_id');
  filterSelect('#family_id', '#training_id', 'family_id');

  filterSelect('#training_id', '#category_id', 'training_id');
  filterSelect('#training_type_id', '#option_id', 'training_type_id');

  // popovers
  $(document).on('mouseover', '.has-popover', function() {
    const target = $(this).data('target');
    $('#' + target).css('display', 'block');
    $(`.has-popover[data-target=${target}]`).addClass('hover');
  });

  $(document).on('mouseout', '.has-popover', function() {
    const target = $(this).data('target');
    $('#' + target).css('display', 'none');
    $(`.has-popover[data-target=${target}]`).removeClass('hover');
  });

  // Bootstrap popovers
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  [...popoverTriggerList].map(el => {
    new bootstrap.Popover(el, {
      trigger: 'hover focus'
    });
  });



  //
  const togglePassword = $('#password-toggle');
  
  togglePassword.on('click', () => {
    var type = $('#password').attr('type') === 'password' ? 'text' : 'password';
    $('#password').attr('type', type);
    togglePassword.children('i').toggleClass('fa-eye-slash fa-eye');
  });

});
