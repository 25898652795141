$(() => {

  if ($('#authorisation-form').length === 0) {
    return;
  }

  //
  $(document).on('change', '#authorisation-form select[name=trainee_id]', function () {
    const url         = $('#trainee-form').data('url');
    const trainee_id  = $(this).val();
    $.get(`${url}?trainee_id=${trainee_id}`, (html) => {
      $('#trainee-form').html(html);
      initRepeaterTrainings();
      // reinit select2
      window.initSelect2();
      window.initDatepicker();
      filterCategories(this);

      // auto select certificate
      const options = $('select[name=certificate_id] option');
      if (options.length > 1 && !$('select[name=certificate_id]').val()) {
        $('select[name=certificate_id]').val(options.eq(1).val());
        $('select[name=certificate_id]').trigger('change');
      }
    });
  });

  //
  $(document).on('change', '#authorisation-form select[name=certificate_id]', function () {
    const trainee_id      = $('select[name=trainee_id]').val() || $('input[name=trainee_id]').val();
    const certificate_id  = $('select[name=certificate_id]').val();
    const url             = `/client/authorisations/habelec/habelec_form?trainee_id=${trainee_id}&certificate_id=${certificate_id}`;

    $.get(url, (html) => {
      $('#habelec-form').html(html);
      // reinit select2
      window.initSelect2();
      window.initDatepicker();
    });
    updateCertificateDates();
    updateExpireRecap();
  });

  const updateCertificateDates = () => {
    // update dates
    const option = $('select[name=certificate_id]').find('option:selected');
    $('#obtained_at').text(option.data('obtained_at'));
    $('#expires_at').text(option.data('expires_at'));
  };
  updateCertificateDates();

  // filter categories by training
  const filterCategories = (el) => {
    el = el || document;
    $(el).find('select.filter-categories').each(function() {
      const select = $(this);
      const parent = select.closest('[data-repeater-item]');
      if (parent.data('filter-categories')) {
        // already initialized
        return;
      }
      parent.data('filter-categories', true);
      const options = $(this).data('options') || select.find('option').clone();
      $(this).data('options', options);
      parent.find('select.filter-training').on('change', function() {
        select.find('option').remove();
        const machine_name  = $(this).find('option:selected').data('machine_name');
        console.log(machine_name);
        parent.find('.with-machine').toggle(!!machine_name);
        parent.find('.without-machine').toggle(!machine_name);
        if (machine_name) {
          parent.find('input#machine_name').val(machine_name);
          parent.find('input#machinename').val(machine_name);
        } else {
          const training_id   = $(this).find('option:selected').data('training_id');
          options.each(function() {
            const option = $(this).clone();
            if ($(option).data('training_id') === training_id) {
              option.attr('selected', 'selected');
              select.append(option);
            }
          });
          window.initSelect2();
        }
      });
      parent.find('select.filter-training').trigger('change');
    });
  };

  const updateIds = () => {
    $('select.select2-multiple').each(function () {
      // update id
      const name = $(this).attr('name');
      $(this).attr('id', name);
      $(this).parent().find('label').attr('for', name);
    });
  };

  // repeater : trainings
  const initRepeaterTrainings = () => {
    const repeater_training = $('#repeater-trainings').repeater({
      // initEmpty: true,
      isFirstItemUndeletable: false,
      show: function () {
        $(this).show();
        window.initSelect2();
        updateIds();
        filterCategories(this);
      },
      ready: function() {
        updateIds();
      }
    });
    if (repeater_training.setList) {
      repeater_training.setList(window.trainings);
    }
  };
  initRepeaterTrainings();
  // filterCategories();
  
  if ($('.display-medical-form')) {
    $('.display-medical-form').hide();
  }

  

  $('#display-medical-form-action').on('click', () => { 
    $('.display-medical-form').show();
  });

  $('#medical-certificate-form').on('submit', (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = {
      name: $('#name').val(),
      doctor_name: $('#doctor_name').val(),
      emission_date: $('[name="emission_date"]').val(),
      validity_duration: $('#validity_duration').val(),
      end_date: $('[name="end_date"]').val(),
      details: $('#details').val(),
      document: $('#document').val(),
    };

    const trainee_id = $('#trainee_id').val();

    if (!trainee_id) { 
      return;
    }

    $.ajax({
      type: 'POST',
      enctype: 'multipart/form-data',
      url: `/client/trainees/${trainee_id}/medical_certificates`,
      data: formData,
      success: function(response) {
        console.log(response);
        alert('Form submitted successfully!');
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.error(errorThrown); 
        alert('Error submitting form!');
      }
    });
  });

  // 
  $(document).on('blur', '#end_date', function () {
    if (!$('#due_date').val()) {
      $('#due_date').val($(this).val());
    }
  });
  
  // update recap expirations
  const updateExpireRecap = () => {
    const html = ['<div class="fw-bold mb-1 ">Récapitulatif des échéances : </div>'];
    $('select#training_type_id, select#certificate_id, select#medical_certificates_ids').each(function() {
      const option = $(this).find('option:selected');
      option.each(function() {
        if ($(this).val()) {
          html.push(`<div class="row"><div class="col-6">${$(this).text()} :</div><div class="col-6 fw-bold text-info set-exires-at cursor-pointer">${$(this).data('expires_at')}</div></div>`);
        }
      });
    });
    // new medical certificate
    if ($('input[name=name]').val()) {
      html.push(`<div class="row"><div class="col-6">${$('input[name=name]').val()} :</div><div class="col-6 fw-bold text-info set-exires-at cursor-pointer">${$('input[name=end_date]').val()}</div></div>`);
    }
    $('#expire-recap').html(html.join(''));
  };
  updateExpireRecap();

  $(document).on('change', 'select#training_type_id, select#certificate_id, select#medical_certificates_ids, input[name=name], input[name=end_date]', updateExpireRecap);

  $(document).on('click', '.set-exires-at', function() {
    $('input[name=expires_at]').val($(this).text());
    return false;
  });

});
